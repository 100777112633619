import {
  title,
  defaultFont,
  basicColor,
  whiteColor,
  grayColor
} from "components/primary-style.js";

const typographyStyle = {
  defaultFontStyle: {
    ...defaultFont,
    fontSize: "15px"
  },
  defaultHeaderMargins: {
    marginTop: "20px",
    marginBottom: "10px"
  },
  tag: {
    ...title,
    color: "#aaa",
    marginTop: "5px",
    marginBottom: "10px",
    fontSize: "11px",
    fontWeight: "600",
    fontFamily: '"DINProRegular", sans-serif',
    letterSpacing: "0.3px !important"
  },
  quote: {
  padding: "10px 20px",
  margin: "0 0 20px",
  fontSize: "1.25rem",
  borderLeft: "5px solid " + grayColor[2]
  },
  quoteText: {
    margin: "0 0 10px",
    fontStyle: "italic"
  },
  quoteAuthor: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: grayColor[10]
  },
  mutedText: {
    "&, & *": {
      color: grayColor[7],
      display: "inline-block"
    }
  },
  smallText: {
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1",
    color: basicColor
  }
};

export default typographyStyle;
