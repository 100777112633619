import React from "react";
import classNames from "classnames";
// components
import { makeStyles, Card, CardBody, CardHeader, GridItem, GridContainer, Link, Tag, Typography } from 'components/indexComponents.js';
// images
import { headerVideoHacks } from "pages/indexImages.js";
//icons
// sections
import Breadcrumbs from "pages/Articles/VideoHacks/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import videoHacksStyle from "pages/Articles/VideoHacks/videoHacksStyle.js";

const useStyles = makeStyles(videoHacksStyle);

export default function VideoHacksPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerVideoHacks})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>Truck&amp;Trailer Video Hacks</h1>
              <h3 className={classes.pageHeaderTitle}>Learn How to Fix Top Common Issues and Perform Basic Repairs</h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
          <Breadcrumbs />
          <GridContainer>
            <GridItem xs={12} sm={8} md={8} className={classes.description}>
              <p className={classes.description} style={{padding: "10px"}}>
                Daily truck/trailer issues and minor repairs are almost a constant in trucking business. So, 
                one day we decided to make life easier on our drivers and on our fleet department in whole. 
                Instead of wasting a lot of time spent on the phone describing how to deal with each issue, 
                we started this series of Video Tutorials describing how to perform simple Truck&amp;Trailer 
                Hacks (minor but costly repairs) and how to fix most common issues we face on a daily basis, 
                so feel free to take advantage of them :) 
                <p />Feel free to&nbsp;
                <span className={classes.link}>Share</span>,&nbsp; 
                <span className={classes.link}>Bookmark</span>,&nbsp;
                <Link className={classes.link} href="https://www.youtube.com/channel/UCnB-SPkNMT7XIcjjEd02llw">Subscribe</Link>
                &nbsp;and don't miss out on Free but Valuable info!
              </p>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/TQ-20oI05fs"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How to Perform a Reset on Freightliner Cascadia Evolution"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #FREIGHTLINER&nbsp; #CASCADIA EVOLUTION</Tag>
                  <h4 className={classes.title}>How to Perform a Reset on Freightliner Cascadia Evolution</h4>
                  <p className={classes.description}>
                    If you get a Check Engine Light or MIL Light on your Dashboard, first thing I recommend is 
                    to perform a RESET. While you're doing it, Check make sure all your Liquids are between Half 
                    and Full (Coolant &amp; Oil) and if it's an electrical issue, performing a Reset usually helps 
                    to clear the codes. Sometimes it takes 2-3 tries before MIL light is cleared and if not 
                    Contact our service department so they can arrange technicians to take a look at your truck 
                    at the nearest Freightliner Dealer.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/5y6dbWbRPB0"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How To Troubleshoot Truck Starter"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #FREIGHTLINER&nbsp; #CASCADIA EVOLUTION</Tag>
                  <h4 className={classes.title}>How To Troubleshoot Truck Starter</h4>
                  <p className={classes.description}>
                    When the truck doesn't start and we see battery is in good condition charge above 12.5V and 
                    battery doesn't smell like a rotten egg, we direct the driver to Shorten the starter. First, 
                    we hit the starter a couple of times and if that doesn't help then we perform the SHORT 
                    (shown in the video).
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/r3w4IaHZTbw"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How to Operate Reefer Unit (Thermo King, Precedent, Carrier)"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #THERMO KING SB230&nbsp; #PRECEDENT S-600&nbsp; #CARRIER</Tag>
                  <h4 className={classes.title}>How to Operate Reefer Unit (Thermo King, Precedent, Carrier)</h4>
                  <p className={classes.description}>
                    Only video you need for basic Reefer Unit operation and very helpful video for Beginners 
                    on how to operate Thermo King Sb 230, Precedent S-600 and Carrier Unit and how to Clear 
                    Codes on them. We didn't go over Precedent unit much because it's the same operation as 
                    SB 230 just slightly different Dashboard. Don't Forget to run PRE TRIPS! it will 
                    definitely save you a lot of time and money!
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/ATteEC7zgM4"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How to Troubleshoot Deflated Air Bags on your Trailer"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #FREIGHTLINER&nbsp; #CASCADIA EVOLUTION</Tag>
                  <h4 className={classes.title}>How to Troubleshoot Deflated Air Bags on your Trailer</h4>
                  <p className={classes.description}>
                    If all four air bags on your trailer are flat by tampering with your leveling valve as I 
                    showed, it normalizes air flow back to trailer air bags - on my experience 9 out of 10 
                    times this solves the issue. Be safe out there guys!
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/LMCr6q9-tdQ"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How to Slide Fifth Wheel on Freightliner Cascadia Evolution"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #FREIGHTLINER&nbsp; #CASCADIA EVOLUTION</Tag>
                  <h4 className={classes.title}>How to Slide Fifth Wheel on Freightliner Cascadia Evolution</h4>
                  <p className={classes.description}>
                    Step by Step Instructions. Make sure you jerk truck as you would while attaching to a trailer 
                    and while sliding it, do it gently otherwise your fifth wheel can brake stop bar and you will 
                    drop the trailer. For Best Fuel Efficiency you want to keep fifth wheel as close to the front 
                    possible, but the draw back you might be overweight on your steers especially if you're a 
                    hauling reefer. Optimal setting is to be closer to the middle.
                  </p>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card plain>
                <div className={classes.iframeContainer}>
                  <iframe
                    height="250"
                    src="https://www.youtube.com/embed/KrEy2APdgsY"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    title="How to Check for Fault Codes on Freightliner Cascadia Evolution"
                  />
                </div>
                <CardBody plain>
                  <Tag>#TRUCK&nbsp; #TRAILER&nbsp; #REPAIR HACKS&nbsp; #FREIGHTLINER&nbsp; #CASCADIA EVOLUTION</Tag>
                  <h4 className={classes.title}>How to Check for Fault Codes on Freightliner Cascadia Evolution</h4>
                  <p className={classes.description}>
                    Step by step instructions and List of fault codes for Freightliner Cascadia Evolution. 
                    You must be parked in order to locate fault codes in your Freightliner.
                  </p>
                </CardBody>
              </Card>
            </GridItem>            
          </GridContainer>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
