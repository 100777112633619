import {
  basicColor,
  yellowColor,
  whiteColor
} from "components/primary-style.js";

const headerLinksStyle = theme => ({
  list: {
    [theme.breakpoints.up("md")]: {
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      WebkitBoxOrient: "horizontal",
      WebkitBoxDirection: "normal",
      MsFlexDirection: "row",
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      display: "block"
    },
    marginLeft: "auto",
    marginTop: "0px",
    display: "flex",
    paddingLeft: "0",
    marginBottom: "0",
    listStyle: "none",
    padding: "0"
  },
  listItem: {
    float: "left",
    color: basicColor,
    background: whiteColor,
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      "& ul": {
        maxHeight: "400px",
        overflow: "scroll"
      },
      width: "100%",
      "&:not(:last-child)": {
        "&:after": {
          width: "calc(100% - 30px)",
          content: '""',
          display: "block",
          height: "1px",
          marginLeft: "15px"
    }}}
  },
  navLink: {
    background: whiteColor,
    color: basicColor,
    position: "relative",
    display: "inline-flex",
    padding: "0.813rem",
    borderRadius: "0",
    width: "auto",
    height: "50px",
    fontWeight: "600",
    fontSize: "14px",
    fontFamily: '"NordsternRegular", sans-serif',
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.3px !important",
    whiteSpace: "nowrap",
    marginTop: "22px",
    lineHeight: "8px",
    "&:hover,&:focus,&:active": {
      color: basicColor,
      borderBottom: "4px solid  " + yellowColor,
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "6px",
      marginTop: "6px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start"
      }
    },
    "& svg": {
      marginRight: "3px",
      width: "20px",
      height: "20px"
    }
  },
  icon: {
    margin: "4px 10px 0 0",
    verticalAlign: "top",
    fontSize: "18px"
  },
  info: {
    color: yellowColor,
    padding: "12px",
    "&:hover, &:active, &:visited": {
      color: basicColor
    },
  },
});

export default headerLinksStyle;
