import {
  main,
  mainRaised,
  container,
  title,
  coloredShadow,
  cardTitle,
  basicColor,
  yellowColor,
  whiteColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";
  
const videoHacksStyle = {
  main,
  mainRaised,
  coloredShadow,
  cardTitle,
  container: {
    ...container,
    zIndex: "2",
    position: "relative"
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "130px 0 130px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderText: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  pageHeaderTitle: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px 0 10px 0"
  },
  title: {
    textAlign: "left",
    textTransform: "uppercase",
    marginTop: "0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    color: basicColor,
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "justify",
    padding: "10px 0",
    margin: "auto",
  },
  link: {
    color: basicColor,
    fontWeight: "600",
    "&:hover,&:focus": {
      color: yellowColor,
      textDecoration: "underline",
    },
    "&:visited": {
      color: basicColor,
    },
  },
  iframeContainer: {
    "& > iframe": {
      width: "100%",
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
  },
  contact: {
    color: basicColor,
    // maxWidth: "1000px",
    paddingBottom: "40px",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

export default videoHacksStyle;
  