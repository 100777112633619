import React from "react";
import classNames from "classnames";
// components
import { 
  makeStyles, Button, Card, CardHeader, CardBody, GridItem, GridContainer, List, NavPills, Typography 
} from 'components/indexComponents.js';
// images
import { 
  headerServices, imgNightDispatchRes, imgDayDispatchRes, imgRecruitingRes, imgSafetyRes, imgDataRes, imgCamerasRes 
} from "pages/indexImages.js";
// sections
import Breadcrumbs from "pages/ServicesPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import servicesPageStyle from "pages/ServicesPage/servicesPageStyle.js";

const useStyles = makeStyles(servicesPageStyle);

export default function ServicesPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerServices})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>Our Services</h1>
              <h3 className={classes.pageHeaderTitle}>Professional Logistics Solutions for Your Trucking Business</h3>
              <h3 className={classes.pageHeaderTitle}>24 / 7 - 365</h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
          <Breadcrumbs />
          <GridContainer justify="center" className={classes.services}>
            {/* NIGHT DISPATCH */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgNightDispatchRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>NIGHT-TIME DISPATCH</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>3rd Shift Dispatcher
                    <p className={classes.description}>
                      Trucking industry requires around the clock operations. We have your back. 
                    <br/>Let us be your After-Hours Dispatch Solution!
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Track&amp;Trace
                    <p className={classes.description}>
                      We track your assets to make sure they are where they need to be, when they need to be there!
                    </p>
                  </Typography>
                  <Typography className={classes.title}>System Updates
                    <p className={classes.description}>
                      In case there is a plan change on customers behalf, we update your TMS system so that your 
                      rate con reflects any changes to your load.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Issuing EFS/Comchecks
                    <p className={classes.description}>
                      Whether your driver needs a late night EFS, Fuel Card Activation, Lumper Charge, our 
                      night crew is here to keep them moving.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Phone/Email Updates
                    <p className={classes.description}>
                      Customers and brokers require around the clock updates. We have you covered!
                    </p>
                  </Typography>
                  <Typography className={classes.title}>ELD Logs
                    <p className={classes.description}>
                      Our Safety Support Team  Available 24/7/365  to make sure your driver has a go 
                      to person to assist with compliance!
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            {/* DAY DISPATCH */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgDayDispatchRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>DAY-TIME DISPATCH</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>Paperwork Processing
                    <p className={classes.description}>
                      We process and finalize all paperwork on your behalf - from new carrier packets to rate confirmations.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Booking Loads
                    <p className={classes.description} style={{textDecoration: "underline"}}>
                      We only book top paying loads averaging over $2.00 per mile in the current market. 
                      We put all our sources to work and don't rely solely on DAT.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Phone &amp; Email Updates
                    <p className={classes.description}>
                      Any updates and adjustments with drivers, brokers or customers get managed by our dispatchers, 
                      so everything and everyone is perfectly coordinated and up to date.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Lumper &amp; Detention
                    <p className={classes.description}>
                      We recover most of our drivers detentions they deserve and in case you choose to operate by our 
                      system - 90% it's drop and hooks. Lumper payment we verify with customer to make sure you are 
                      reimbursed or not deducted from the rate.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Credit Checks
                    <p className={classes.description}>
                      Any broker we don't use on a daily basis gets credit checked to ensure you will be paid on time.
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            {/* RECRUITING */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgRecruitingRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>RECRUITING</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>Recruiting Drivers
                    <p className={classes.description}>
                      Driver shortage plays vital role in trucking industry. Cost to carriers of a new hire can 
                      range from $6,000 to 8,000 if it’s done in house with all additional expenses like marketing, 
                      incentive bonuses, travel compensations.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Experience
                    <p className={classes.description}>
                      Few of our team members have worked in the logistics industry as human resources and recruiters 
                      in different companies back in the days. Drivers tend to be loyal to people who hired them in 
                      the past and treated them fairly throughout the years. Our combined driver pool base is close 
                      to 3,000 drivers. If you are a carrier who has good intentions we can be a solution to your 
                      standing fleet.
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            {/* SAFETY SUPPORT */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgSafetyRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>SAFETY SUPPORT</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>Log Book Monitoring
                    <p className={classes.description}>
                      Drivers are humans and tend to make mistakes, but when it comes to HOS even minor mistakes 
                      on the log books can cost thousands in fines to trucking companies, as well as potential 
                      safety score volatility. Our dedicated Safety Support Team is at your service 24/7 to keep 
                      your drivers on track with HOS Rules and Regulations.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>ELD Software
                    <p className={classes.description}>
                      We have experience in most ELD software, in case of provider failures our staff is trained 
                      on paper logs to serve as a back up and go to person for advice.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Increase Efficiency
                    <p className={classes.description}>
                      Dispatchers job is to book loads and their knowledge of HOS often is very limited, many 
                      companies have their planners and dispatchers marching in cadence with their safety support 
                      team. Poorly planned routes could account for up to 30% of revenue losses. Let us help you plan!
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Provisions Knowledge
                    <p className={classes.description}>
                      In case of adverse weather conditions or severe traffic jams, your driver runs out of hours, 
                      what is your game plan to get your truck where it needs to be?
                    </p>
                  </Typography>
                  <Typography className={classes.title}>
                    Driver runs out of hours, shuts off the ELD and continues to drive, 
                    who will notify your dispatcher and safety director?
                    <p className={classes.description}>
                      Our safety support team is constantly monitoring your drivers progress on Hour Cycles.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>ELD Technical Support
                    <p className={classes.description}>
                      ELD doesn’t transfer data or stops recording locations and constantly gets disconnected? 
                      Our team will be the first ones on the line to help your driver.
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            {/* DATA ENTRY */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgDataRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>DATA ENTRY</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>Data Entry
                    <p className={classes.description}>
                      Collecting and entering data to a database of your choice while maintaining accurate records.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Reduce Your Operational Cost and Promote Efficiency
                    <p className={classes.description}>
                      It’s a time consuming task that requires a high degree of concentration, time your 
                      dispatchers spend on entering rate confirmations in to the (TMS) system can be wisely 
                      spent on searching for higher paying loads and reduced mistakes.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Uncompromising Quality Assurance
                    <p className={classes.description}>
                      Experience and policies we have put in place ensure accuracy to 100%. Mistakes in data 
                      entry lead to mistakes in accounting which can transmit to immense operational costs. 
                      Let our team of professionals take charge.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Data Entry Software
                    <p className={classes.description}>
                      We are experts in TMW, Quickbooks and PCS in the case different software is needed, 
                      our team is easily trainable and will pass any tests prior to taking on the project.
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
            {/* CAMERA MONITORING */}
            <GridItem xs={12} sm={9} md={9} className={classes.section}>
              <Card> 
                <CardHeader style={{ backgroundImage: `url(${imgCamerasRes})`, borderRadius: "0"}}>
                  <h4 className={classes.titleBox}>CAMERA / LOCATION MONITORING</h4> 
                </CardHeader>
                <CardBody>
                  <h5 className={classes.note}>- See Pricing Page or Contact Us for a Free Quote -</h5>                    
                  <Typography className={classes.title}>Camera Monitoring Projects
                    <p className={classes.description}>
                      Yomiro Source started with camera monitoring at the early stages of our development.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Safety Conscious Carriers/Insurance Companies
                    <p className={classes.description}>
                      Many fleets have acquired cameras inside their trucks, but most utilize it only for a quarter 
                      of its capabilities. For example, in order to oversee a fleet of 500 units there is approximately 
                      a 15-operator crew required to handle the project. Tightening budget of trucking companies 
                      can’t afford to handle this workload in-house, outsourcing is their only option.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Reports
                    <p className={classes.description}>
                      We will report your driver’s reckless behaviour, unsafe driving, distracted driving or save 
                      a video in case of an accident. In most carriers, the absence of camera monitoring team in 
                      case of an accident occurred throughout an after hours shift can take hours if not days to 
                      locate and record evidence from the accident scene.
                    </p>
                  </Typography>
                  <Typography className={classes.title}>Bad Weather
                    <p className={classes.description}>
                      Internet has many tools to view weather in all parts of the country, but it is still far from 
                      being perfect and the only person who can decide if it’s safe to drive is the driver himself. 
                      There are times when camera monitoring crew prevented potential accidents by viewing road 
                      conditions through driver’s dash cams in bad weather conditions. Our crew made a few very 
                      good calls in the past, one was by pulling two trucks off the road just miles prior to 2015 
                      Feb I- 94 Kalamazoo, Michigan 100 car pile up.
                    </p>
                    <p/>
                  </Typography>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
