import {
  container,
  title,
  section,
  basicColor,
  yellowColor
} from "components/primary-style.js";

const footerStyle = {
  ...container,
  title,
  section: {
    ...section,
    padding: "70px 0px"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  info: {
    color: basicColor,
    padding: "0.9375rem",
    fontWeight: "600",
    fontSize: "15px",
    textTransform: "uppercase",
    borderRadius: "0",
    textDecoration: "none",
    position: "relative",
    display: "block",
    float: "right",
    letterSpacing: "0.3px !important",
    "&:hover, &:active, &:visited": {
      color: basicColor
    },
  },
  icon: {
    margin: "3px 5px 0 0",
    verticalAlign: "top",
    fontSize: "18px"
  },
  listItem: {
    color: basicColor,
    padding: "0.9375rem",
    fontWeight: "600",
    fontSize: "13px",
    textTransform: "uppercase",
    borderRadius: "0",
    textDecoration: "none",
    position: "relative",
    display: "block",
    letterSpacing: "0.3px !important",
    "&:hover, &:active": {
      color: yellowColor
    },
    "&:visited": {
      color: basicColor
    },
  },
  left: {
    float: "left !important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  copyright: {
    color: basicColor,
    padding: "15px 0",
    margin: "0",
    float: "right",
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textDecoration: "none",
    letterSpacing: "0.3px !important"
  },
  copyrightLink: {
    color: "#3c4858",
    margin: "0",
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.3px !important",
    "&:hover, &:active": {
      color: yellowColor
    },
    "&:visited": {
      color: basicColor
    },
  }
};

export default footerStyle;
