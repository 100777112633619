import {
  main,
  mainRaised,
  container,
  basicColor,
  yellowColor,
  whiteColor,
  blackColor,
  hexToRgb,
} from "components/primary-style.js";
  
const faqPageStyle = {
  main,
  mainRaised,
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    padding: "0 0 30px 0"
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "140px 0 140px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderTitle: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px 0 10px 0"
  },
  accordion: {
    padding: "30px 0 0 0",
    marginLeft: "auto",
    marginRight: "auto",
  },
  section: {
    padding: "0 20px 30px 20px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    color: basicColor,
    fontSize: "15px",
    fontWeight: "600",
    fontFamily: `"DINProRegular", sans-serif`,
  },
  description: {
    color: basicColor,
    fontSize: "15px",
    fontWeight: "400",
    fontFamily: '"DINProRegular", sans-serif',
    textAlign: "justify",
    // margin: "auto",
  },
  contact: {
    textAlign: "center",
    border: "4px solid #ccc",
    borderRadius: "0px",
    padding: "30px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  icon: {
    margin: "3px 5px 0 0",
    verticalAlign: "top",
    fontSize: "18px",
  },
  textCenter: {
    textAlign: "center",
  },
};

export default faqPageStyle;
  