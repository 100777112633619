import React from "react";
// components
import { makeStyles, GridContainer, GridItem, Subtitle } from 'components/indexComponents.js';
// icons
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked";

import sectionAdvantagesStyle from "pages/HomePage/sections/sectionAdvantagesStyle.js";

const useStyles = makeStyles(sectionAdvantagesStyle);

export default function SectionAdvantages({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.about}>
          <GridContainer>
            <GridItem xs={12} className={classes.title}>
              <h3 >YOU DRIVE – WE MANAGE EVERYTHING ELSE</h3>
            </GridItem>
            <GridItem xs={12} className={classes.description}>
              <p>
              Yomiro Source is a true extension of our customers operation. Whether you are a carrier, freight broker, 
              security or insurance company, our team is ready to offer solutions and a tailored approach for your 
              company needs.We process all your inbound/outbound calls with exceptional service at a step ahead pace. 
              Our team will relieve you from unnecessary struggles and overhead while searching for your Safety Support 
              Staff, Daytime Dispatch or After Hour Dispatch, Camera/Location Monitoring Team, Recruiting or Data Entry 
              Crew. With our skilled and experienced team, comprehensive industry knowledge and use of variety of latest 
              technologies &amp; processes, we are best placed to help achieve our customers goals in cost savings and 
              quality control. Our belief that excellent communication is the building block of a successful dispatching 
              service and we are always willing to pull an extra mile to offer quality care to all our clients. 
              <p />Contact us today to learn how we can help reducing your labor cost by as much as 50%!
              </p>
            </GridItem>
          </GridContainer>
          {/* ADVANTAGES Bullets */}
          <h3 className={classes.title + " " + classes.advantages}> > .COMPETENT. > .RELIABLE. > .LOGISTICS. > </h3>
          <GridContainer className={classes.advantages}>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle30}>Nationwide Coverage</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle30}>24/7 Technical Support</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle30}>24/7 Dispatch Team</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle10}>Enhanced Efficiency</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle10}>Over 10 Years of Experience in the Industry</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle30}>Vehicle Tracking System &amp; Camera Monitoring</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle10}>Trustworthy Third-Party Logistics</h4>
            </GridItem>
            <GridItem xs={6} sm={6} md={3}>
              <RadioButtonChecked className={classes.icon} />
            <h4 className={classes.subtitle10}>Amazon Relay Experts</h4>
            </GridItem>
          </GridContainer>
          {/* ADVANTAGES CallCenter */}
          <h3 className={classes.title + " " + classes.advantages}>24 > 7 > 365 CALL CENTER</h3>
          <GridContainer className={classes.advantages}>
            <GridItem xs={12} sm={6} md={4}>
              {/* <RadioButtonChecked className={classes.icon} /> */}
            <h4 className={classes.subtitleCalls}>Quality Communication</h4>  
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              {/* <RadioButtonChecked className={classes.icon} /> */}
            <h4 className={classes.subtitleCalls}>Emergency Road Services</h4>  
            </GridItem>
            <GridItem xs={12} sm={6} md={4}>
              {/* <RadioButtonChecked className={classes.icon} /> */}
            <h4 className={classes.subtitleCalls}>Driver Accident Hotline</h4>  
            </GridItem>
          </GridContainer>
        </div>
    </div>
  </div>
  );
}
