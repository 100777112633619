import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "components/_scss/primary-style.scss?v=1.8.0";
// pages
import AboutUsPage from "pages/AboutUsPage/AboutUsPage.js";
// import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
import ClientsPage from "pages/ClientsPage/ClientsPage.js";
import ErrorPage from "pages/ErrorPage/ErrorPage.js";
import FaqPage from "pages/FaqPage/FaqPage.js";
import HomePage from "pages/HomePage/HomePage.js";
import PricingPage from "pages/PricingPage/PricingPage.js";
import ServicesPage from "pages/ServicesPage/ServicesPage.js";
import TechnologyPage from "pages/TechnologyPage/TechnologyPage.js";
import TruckTrailerVideoHacks from "pages/Articles/VideoHacks/VideoHacksPage.js";

import TagManager from 'react-gtm-module'
// fonts
import 'components/_fonts/Nordstern-Normal.ttf';
import 'components/_fonts/Nordstern-Bold.ttf';
import 'components/_fonts/DINPro-Regular.ttf';
import 'components/_fonts/DINPro-Bold.ttf';

import './index.css';

var history = createBrowserHistory();

TagManager.initialize({
  gtmId: 'GTM-PWRRS57'
})

ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/about" component={AboutUsPage} />
      {/* <Route path="/blog-post" component={BlogPostPage} /> */}
      <Route path="/clients" component={ClientsPage} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/faq" component={FaqPage} />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/services" component={ServicesPage} />
      <Route path="/technology" component={TechnologyPage} />
      <Route path="/truck-trailer-video-hacks" component={TruckTrailerVideoHacks} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
