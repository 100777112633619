import {
    main,
    mainRaised,
    container,
    title,
    basicColor,
    whiteColor,
    blackColor,
    hexToRgb
  } from "components/primary-style.js";
  
  const technologyPageStyle = {
    main,
    mainRaised,
    container: {
      ...container,
      zIndex: "2",
      position: "relative"
    },
    pageHeader: {
      position: "relative",
      height: "auto",
      backgroundPosition: "50%",
      backgroundSize: "cover",
      margin: "0",
      padding: "130px 0 130px 0",
      display: "flex",
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      "&:before": {
        background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
      },
      "&:after,&:before": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: "''"
      }
    },
    pageHeaderText: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto"
    },
    pageHeaderTitle: {
      color: whiteColor,
      textTransform: "uppercase",
      textDecoration: "none",
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "10px 0 10px 0"
    },
    titleBox: {
        maxWidth: "900px",
        textAlign: "center",
        borderRadius: "0px",
        padding: "30px 40px 30px 40px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    title: {
      ...title,
      textAlign: "center",
      marginTop: "0"
    },
    description: {
      color: basicColor,
      fontFamily: '"DINProRegular", sans-serif',
      fontSize: "15px",
      fontWeight: "400",
      textAlign: "center",
      margin: "auto",
    },
  };
  
  export default technologyPageStyle;
  