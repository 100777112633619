import {
    main,
    mainRaised,
    container,
    title,
    basicColor,
    whiteColor,
    blackColor,
    hexToRgb
  } from "components/primary-style.js";
  
  const clientsPageStyle = {
    main,
    mainRaised,
    container: {
      ...container,
      zIndex: "2",
      position: "relative"
    },
    pageHeader: {
        position: "relative",
        height: "auto",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        margin: "0",
        padding: "120px 0 120px 0",
        display: "flex",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center",
        "&:before": {
            background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
      }
    },
    pageHeaderText: {
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto"
    },
    pageHeaderTitle: {
        color: whiteColor,
        textTransform: "uppercase",
        textDecoration: "none",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "10px 0 10px 0"
    },
    title: {
        ...title,
        textAlign: "center",
        marginTop: "20px"
    },
    titleBox: {
        color: basicColor,
        maxWidth: "1000px",
        paddingBottom: "40px",
        marginLeft: "auto",
        marginRight: "auto",
    },
    descriptionTitle: {
        color: basicColor,
        fontSize: "16px",
        fontWeight: "300",
        fontFamily: '"DINProRegular", sans-serif',
        textAlign: "center",
        padding: "30px 10px 40px 10px",
        margin: "auto",
    },
    description: {
        color: basicColor,
        fontFamily: '"DINProRegular", sans-serif',
        fontSize: "15px",
        fontWeight: "400",
        textAlign: "justify",
        padding: "20px 10px 30px 10px",
        margin: "auto",
    },
    disclaimer: {
        padding: "30px 10px 20px 10px",
        margin: "auto",
    },
    disclaimerText: {
        color: "#bbb",
        fontSize: "12px",
        fontWeight: "300",
        fontStyle: "italic",
        fontFamily: '"DINProRegular", sans-serif',
        textAlign: "center",
    },
    logos: {
        position: "relative",
        display: "block",
        height: "40px",
        marginLeft: "auto",
        marginRight: "auto",
    },
  };
  
  export default clientsPageStyle;
  