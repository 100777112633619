import React from "react";
import classNames from "classnames";
// components
import { 
  makeStyles, Button, Checkbox, FormControlLabel, Input, GridItem, GridContainer, Typography 
} from 'components/indexComponents.js';
// images
import { headerAbout } from "pages/indexImages.js";
//icons
import Check from "@material-ui/icons/Check";
// sections
import Breadcrumbs from "pages/AboutUsPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import aboutUsStyle from "pages/AboutUsPage/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const [checked, setChecked] = React.useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerAbout})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>About Us</h1>
              <h3 className={classes.pageHeaderTitle}>Find out more about how we work</h3>
              <h3 className={classes.pageHeaderTitle}>24 / 7 - 365</h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
          <Breadcrumbs />
          <GridContainer>
            <GridItem md={9} className={classes.description}>
              <h6 className={classes.title}>Our Company</h6>
              <Typography className={classes.description}>
                We are an Outsourcing Company and it all started with one-man team.
                <p/>Maksym Fomin always had an eye out on logistics industry, after completing a broker training realized 
                that some hands on the job experience needed to better understand trucking business, got his CDL and 
                worked as company driver, lease operator, owner operator dealing with dry vans, reefers and some car 
                hauling. There afterwards got a job offer from Cadence Premier Logistics (Lt Cargo at the time) and 
                worked his way up in just under 7 years from Technical Support Associate to Human Resources. 
                <p/>Besides all accomplishments in the company, one of the major ones was to build an external organized 
                after hours dispatch team capable of handling 300+ trucks with fraction of manpower the company was 
                employing previously, at a fraction of the cost respectively.
                <p/>Since Yomiro project launched, it gained popularity in the community with people familiar to how we 
                perform and effort everyone is chipping in. Yomiro team saw value in offering services to other carriers 
                and we’ve carefully grew our operation from 15 employees to 50, while each team member has successfully 
                accomplished rigorous on-boarding process. We are based in Orlando, Fl with our operational center in 
                Kiev, Ukraine. We believe since trucking/brokering/security is a 24/7/365 operation – our customer 
                service is available to you 24/7 just to assure quality and expectations are reached.
                <p/>Every single after-hour dispatcher is sufficiently trained in all fields of trucking operation: 
                Safety Compliance Support, Accident Procedures, Loading/Unloading Processes, Camera/Location Monitoring, 
                Roadside Assistance (tech support), Booking Loads while negotiating better rates compared to daytime 
                operation due to nature of the rates in the after-hours... 
                <p/>Recent stagnant freight market and compliance changes have been a major headwind to the fiercely 
                competitive trucking industry. Companies operating within the sector are constantly seeking new 
                approaches to cut cost and generate advantages over their competitors. With it all being our tail wind, 
                let us become yours, with our extensive experience within the industry Yomiro Source has ability to 
                provide you with economically sound solutions to help grow your company. There are numerous advantages 
                in outsourcing your labor force, Massive Financial Savings, Staffing Flexibility, Enhanced Efficiency 
                are just to name the very few. 
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.contact}>
            <GridItem xs={12}>
              <h6 className={classes.title}>Contact us today</h6>
              <h3 className={classes.title} style={{fontSize: "14px", marginTop: "0"}}>
                and learn how we can save you money<span style={{ fontFamily: '"DINProRegular", sans-serif' }}>!</span>
              </h3>
            </GridItem>
            {/* <GridItem xs={12}>
              <form>
                <GridContainer className={classes.contactForm}>
                  <GridItem xs={12} sm={6} md={6}>
                    <Input labelText="First Name" id="first" formControlProps={{fullWidth: true}}/>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Input labelText="Last Name" id="last" formControlProps={{fullWidth: true}}/>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Input labelText="Email Address" id="email-address" formControlProps={{fullWidth: true}}/>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Input labelText="Phone Number (optional)" id="phone-number" formControlProps={{fullWidth: true}}/>
                  </GridItem>
                  <GridItem xs={12}>
                    <Input labelText="Your Message" id="message" formControlProps={{fullWidth: true}} 
                          inputProps={{multiline: true, rows: 5}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControlLabel control={
                      <Checkbox 
                        tabIndex={-1} onClick={() => handleToggle(1)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{checked: classes.checked, root: classes.checkRoot}}
                      />
                    }
                    classes={{ label: classes.label }}
                    label="I'm not a robot"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={6}>
                    <Button variant="outlined" color="secondary" className={classes.button}>
                      Send Message
                    </Button>
                </GridItem>
              </GridContainer>
              </form>
            </GridItem> */}
          </GridContainer>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
