import React from "react";
import classNames from "classnames";
// components
import { makeStyles, Button, GridItem, GridContainer, PhoneNumber } from 'components/indexComponents.js';
// images
import { headerHome } from "pages/indexImages.js";
// sections
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";
import SectionAdvantages from "pages/HomePage/sections/SectionAdvantages.js";
import SectionServices from "pages/HomePage/sections/SectionServices.js";
import SectionSoftware from "pages/HomePage/sections/SectionSoftware.js";
import SectionClients from "pages/HomePage/sections/SectionClients.js";

import homePageStyle from "pages/HomePage/homePageStyle.js";

const useStyles = makeStyles(homePageStyle);

export default function Components() {
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerHome})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderTitle}>
              <h1 className={classes.title}>TRUCKING DISPATCH &amp;</h1>
              <h1 className={classes.title}>LOGISTICS STAFFING SERVICES</h1>
              <h3 className={classes.title}>24/7 – 365</h3>
              <h3 className={classes.title}>Your One&amp;Only Sourcing Solution</h3>
              <br />
              <h6 className={classes.titleUnderline}>We specialize in:</h6>
              <h4 className={classes.titleDescription}>
                Safety Support – Daytime Dispatch – Night-Time Dispatch – Data Entry – Camera/Location Monitoring Team – Recruiting
              </h4>
              <h6 className={classes.titleUnderline}>We are here for:</h6>
              <h4 className={classes.titleDescription}>
                Truck Load Carriers – Freight Brokers – Insurance Companies – Security Companies
              </h4>
              <h3 className={classes.title}>CALL US for a FREE QUOTE<span style={{ fontFamily: '"DINProRegular", sans-serif' }}>!</span></h3>
              <Button variant="outlined" color="secondary" href="tel:1-407-813-2240" className={classes.button}>
                <PhoneNumber white/>
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
          <SectionAdvantages />
        </div>
        <SectionServices />
        <SectionSoftware />
        <SectionClients />
      </div>
      <SiteFooter />
    </div>
  );
}
