import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ArrowRight from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    padding: "10px 0 10px 0"
  },
  breadcrumb: {
    color: "#9ba7b1",
    fontSize: "12px"
  },
  link: {
    color: "#9ba7b1",
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3px !important",
    "&:hover, &:visited": {
      color: "#9ba7b1"
    },
  },
  linkCurrent: {
    color: "#9ba7b1",
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3px !important"
  },

}));

export default function SimpleBreadcrumbs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRight />} className={classes.breadcrumb}>
          <Link href="/" className={classes.link}>
            Home
          </Link>
          <Typography className={classes.linkCurrent}>
            FAQ
          </Typography>
        </Breadcrumbs>
    </div>
  );
}
