import {
  main,
  mainRaised,
  container,
  title,
  basicColor,
  yellowColor,
  whiteColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";

const aboutUsStyle = {
  main,
  mainRaised,
  container: {
    ...container,
    zIndex: "2",
    position: "relative"
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "120px 0 120px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderText: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  pageHeaderTitle: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px 0 10px 0"
  },
  title: {
    ...title,
    textAlign: "center",
    marginTop: "0"
  },
  description: {
    color: basicColor,
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "justify",
    padding: "10px 10px 20px 10px",
    margin: "auto",
  },
  contact: {
    color: basicColor,
    // maxWidth: "1000px",
    paddingBottom: "40px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  contactForm: {
    color: basicColor,
    maxWidth: "820px",
    textAlign: "center",
    border: "4px solid #ccc",
    borderRadius: "0px",
    padding: "20px 40px 30px 40px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    color: basicColor,
    textAlign: "center",
    border: "1px solid #ccc",
    borderRadius: "0px",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: '"NordsternBold", sans-serif',
    "&:hover,&:focus,&:active": {
      color: yellowColor,
      textAlign: "center",
      border: "1px solid #ccc",
      borderRadius: "0px",
    }
  },
};

export default aboutUsStyle;
