import React from "react";
// components
import { makeStyles, GridContainer, GridItem, Link, Typography } from 'components/indexComponents.js';
// images
import { 
  logoCadencePremier, logoFinco, logoGlobalExpress, logoOrlandoExpress, logoApexSecurity, logoGPSTab 
} from "pages/indexImages.js";

import sectionClientsStyle from "pages/HomePage/sections/sectionClientsStyle.js";

const useStyles = makeStyles(sectionClientsStyle);

export default function SectionClients({ ...rest }) {
const classes = useStyles();

return (
  <div className="cd-section" {...rest}>
    <h6 className={classes.title}>OUR CLIENTS</h6>
    <br/>
    <div className={classes.container}>
      <GridContainer className={classes.titleBox}>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoCadencePremier} title="Cadence Premier Logistics" alt="Cadence Premier Logistics" className={classes.logos}>
            </img>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoFinco} title="FinCo Trucking Inc." 
              alt="FinCo Trucking Inc."
              style= {{height: "65px", marginTop: "-13px"}}
              className={classes.logos}>
            </img>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoGlobalExpress} title="Global Express Inc." alt="Global Express Inc." className={classes.logos}>
            </img>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoOrlandoExpress} title="Orlando Express Inc." alt="Orlando Express Inc." className={classes.logos} 
              style= {{height: "55px", marginTop: "-13px"}}>
            </img>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoApexSecurity} title="Apex Security" alt="Apex Security" className={classes.logos}>
            </img>
          </a>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <a href="/clients">
            <img 
              src={logoGPSTab} title="GPSTab ELD/AOBRD" alt="GPSTab ELD/AOBRD" className={classes.logos}>
            </img>
          </a>
        </GridItem>
      </GridContainer>
    </div>
  </div>
);
}

