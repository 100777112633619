import {
    container,
    title,
    section,
    basicColor,
    blackColor,
    whiteColor,
    hexToRgb
  } from "components/primary-style.js";
  
  const sectionClients = {
    container,
    title: {
      ...title,
      textAlign: "center",
      marginTop: "20px"
    },
    titleBox: {
      color: basicColor,
      maxWidth: "1000px",
      paddingBottom: "30px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    description: {
      color: basicColor,
      fontFamily: '"DINProRegular", sans-serif',
      fontSize: "15px",
      fontWeight: "400",
      textAlign: "justify",
      padding: "20px 10px 30px 10px",
      margin: "auto",
    },
    logos: {
      position: "relative",
      display: "block",
      height: "40px",
      marginBottom: "60px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    justifyContent: {
      WebkitBoxPack: "center !important",
      MsFlexPack: "center !important",
      justifyContent: "center !important",
    }
  };
  
  export default sectionClients;
  