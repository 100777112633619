import React from "react";
// components
import { makeStyles, Footer, PhoneNumberIcon } from 'components/indexComponents.js';
// icons
import Room from "@material-ui/icons/Room";
// style
import styles from "components/Footer/siteFooterStyle.js";

const useStyles = makeStyles(styles);

export default function SiteFooter() {
  const classes = useStyles();
  return (
    <div className={classes.section}  style={{paddingTop: '0px', paddingBottom: '0px'}}> 
      <div>

        <Footer
          content={
            <div>
            <div className={classes.left}>
              <span className={classes.info}>
                <Room className={classes.icon}/>
                Orlando, FL &nbsp;&nbsp;&nbsp;
                <PhoneNumberIcon />
              </span>
            </div>
            <div className={classes.copyright}>
              Copyright &copy; {1900 + new Date().getYear()}{" "}
              <a className={classes.copyrightLink} href="/">
                YOMIRO SOURCE
              </a>
                {" "}- All Rights Reserved.
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
