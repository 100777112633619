import React from "react";
// components
import { makeStyles, PhoneNumberIcon } from 'components/indexComponents.js';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// style
import styles from "components/Header/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <a
          href="/"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > HOME
        </a>
      </ListItem>
   
      <ListItem className={classes.listItem}>
        <a
          href="/about"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > ABOUT
        </a>
      </ListItem>

      <ListItem className={classes.listItem}>
        <a
          href="/services"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > SERVICES
        </a>
      </ListItem>

      <ListItem className={classes.listItem}>
        <a
          href="/pricing"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > PRICING
        </a>
      </ListItem>
           
      <ListItem className={classes.listItem}>
        <a
          href="/clients"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > CLIENTS
        </a>
      </ListItem>
      
      <ListItem className={classes.listItem}>
        <a
          href="/faq"
          className={classes.navLink}
          color={window.innerWidth < 960 ? "white" : "white"}
          simple
        > FAQ
        </a>
      </ListItem>

      <ListItem className={classes.listItem} style={{marginTop: "4px"}}>
        <span className={classes.info}>
          <PhoneNumberIcon />
        </span>
      </ListItem>


  </List>
  );
}
