import {
  basicColor,
  yellowColor,
  redColor,
  primaryColor,
  grayColor
} from "components/primary-style.js";

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "30px"
  },
  expansionPanel: {
    boxShadow: "none",
    "&:before": {
      display: "none !important"
    }
  },
  expansionPanelExpanded: {
    margin: "0 !important",
    marginTop: "0",
    marginBottom: "0",
    color: basicColor
  },
  expansionPanelSummary: {
    minHeight: "auto !important",
    backgroundColor: "transparent",
    borderBottom: "1px solid " + grayColor[6],
    padding: "25px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    color: basicColor
  },
  basicExpansionPanelSummary: {
    "&:hover": {
      color: basicColor
    }
  },
  yellowExpansionPanelSummary: {
    color: basicColor,
    "&:hover": {
      color: yellowColor
    }
  },
  redExpansionPanelSummary: {
    "&:hover": {
      color: redColor
    }
  },
  primaryExpansionPanelSummary: {
    "&:hover": {
      color: primaryColor[0]
    }
  },
  expansionPanelSummaryExpaned: {
    "& $expansionPanelSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important"
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important"
      },
      // some jss/css to make the cards look a bit better on Internet Explorer
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        display: "inline-block !important",
        top: "10px !important"
      }
    }
  },
  basicExpansionPanelSummaryExpaned: {
    color: basicColor
  },
  yellowExpansionPanelSummaryExpaned: {
    color: basicColor,
    "&:hover,&:active": {
      color: yellowColor,
    },
  },
  redExpansionPanelSummaryExpaned: {
    color: redColor
  },
  primaryExpansionPanelSummaryExpaned: {
    color: primaryColor[0]
  },
  expansionPanelSummaryContent: {
    margin: "0 !important",
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important"
    },
    transform: "rotate(0deg)",
    color: "inherit",
    right: "10px",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important"
    },
    // some jss/css to make the cards look a bit better on Internet Explorer
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "inline-block !important"
    }
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    marginTop: "0",
    marginBottom: "0",
    color: basicColor,
    fontSize: "15px",
    fontWeight: "600",
    fontFamily: `"DINProRegular", sans-serif`,
    textDecoration: "none",
    "&:hover,&:active": {
      color: yellowColor,
    },
  },
  expansionPanelDetails: {
    display: "block",
    color: basicColor,
    padding: "15px 0px 5px",
    fontSize: "15px",
    fontWeight: "300",
    fontFamily: `"DINProRegular", sans-serif`,
    textDecoration: "none"
  },
});

export default accordionStyle;
