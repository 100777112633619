import React from "react";
import classNames from "classnames";
// components
import { makeStyles, Card, GridItem, GridContainer, Paper, PhoneNumber, Typography } from 'components/indexComponents.js';
// images
import { headerPricing } from "pages/indexImages.js";
//icons
import Call from "@material-ui/icons/Call";
// sections
import Breadcrumbs from "pages/PricingPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import pricingPageStyle from "pages/PricingPage/pricingPageStyle.js";

const useStyles = makeStyles(pricingPageStyle);

export default function PricingPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerPricing})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>Pricing</h1>
              <h3 className={classes.pageHeaderTitle}>{"< "}.Contact Us for a Free Quote.{"  >"}</h3>
              <h3 className={classes.pageHeaderTitle}>
                <Call className={classes.icon} style={{margin:"6px 0 0 0"}}/>
                <PhoneNumber white/>
              </h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Breadcrumbs />
          <GridContainer className={classes.section}>
            <GridItem xs={11} sm={11} md={3} className={classes.card}>
                <h6 className={classes.titlePrice}>Night-Time Dispatch</h6>
                <h2 className={classes.price}>Contact for a Quote</h2>
                <Typography className={classes.description}>
                  Night-Time Dispatching is one of our professional specialties. 
                  <p/>We offer trucking companies quality dispatch service even in the middle of the night, 
                  including but not limited to:
                  </Typography>
                  <Typography className={classes.descriptionLeft}>
                  - GPS Tracking
                  <br/>- TMS System Updates
                  <br/>- Issuing money codes (EFS, Com Checks, activating fuel cards, lumper charge, etc.)
                  <br/>- Phone/Email Updates
                  <br/>- ELD Logs.
                </Typography>
            </GridItem>
            <GridItem xs={11} sm={11} md={3} className={classes.card}>
                <h6 className={classes.titlePrice}>Owner Operator</h6>
                <h2 className={classes.price}>10% Gross</h2>
                <Typography className={classes.description}>
                  If you are owner operator with own MC, 10% from gross total will cover all of your day to 
                  day operations, including but not limited to:
                </Typography>
                <Typography className={classes.descriptionLeft}>
                  - best negotiated rates,
                  <br/>- 24/7 tech support,
                  <br/>- detention/lumper payment collection,
                  <br/>- brokers setups,
                  <br/>- all back-office paperwork,
                  <br/>- factoring and invoicing.
                  <p style={{textAlign: "justify"}}>Contact us for more info to understand the value of our services.</p>
                </Typography>
            </GridItem>
            <GridItem xs={11} sm={11} md={3} className={classes.card}>
                <h6 className={classes.titlePrice}>Trucking Fleet</h6>
                <h2 className={classes.price}>Custom Payment Plan</h2>
                <Typography className={classes.description}>
                  If you are a fleet and in need to externalize any of your services, we have an offer that 
                  will be hard to refuse. No two companies are the same neither their needs, to quote correct 
                  payment plan we need to know the company size, services needed etc.
                  <p style={{paddingBottom: "30px"}}>Contact us for more information.</p>
                </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.section}>
            <GridItem xs={12}>
              <h3 className={classes.title}>Contact us for more information</h3>
            </GridItem>
            <GridItem xs={12} sm={9} md={5} className={classes.contact}>
              <h4>
                Call Us .
                <Call className={classes.icon}/>
                <PhoneNumber />
              </h4>
              <h4>Leave us a message in Live Chat</h4>
            </GridItem>
            <GridItem xs={12}>
              <br/><h4 className={classes.title}>We are at your service 24/7</h4>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
