import React from "react";
// components
import { makeStyles, GridContainer, GridItem, Link, Typography } from 'components/indexComponents.js';
// images
import { imgSoftware, logoDAT, logoAmazonRelay } from "pages/indexImages.js";

import sectionSoftwareStyle from "pages/HomePage/sections/sectionSoftwareStyle.js";

const useStyles = makeStyles(sectionSoftwareStyle);

export default function SectionSoftware({ ...rest }) {
  const classes = useStyles();
  
  return (
    <div className="cd-section" {...rest}>
      <h6 className={classes.title}>BEST SOFTWARE FOR EFFICIENT LOGISTICS</h6>
      <br/>
      {/* Software START */}
      <div
        className={classes.software + " " + classes.section}
        style={{ backgroundImage: `url(${imgSoftware})` }}
      >
        <div className={classes.container}>
          <GridContainer className={classes.titleBox}>
            <GridItem xs={12}>
              <Typography>
                <h1 className={classes.title}>Software We use</h1>
              </Typography>
            </GridItem>
            <br />
            <GridItem xs={12}>
              <Typography className={classes.description}>
                All of our dispatchers are trained in using industry leading TMS Software. Working with 
                a new company is always a smooth transition as we learn the ins and outs of each and every 
                business and tailor a custom dispatch solution.
              </Typography>
            </GridItem>
              <GridItem xs={12} style={{padding: "30px 0 10px 0", justifyContent: "center"}}>
                <img src={logoDAT} alt="DAT Truckers Edge" style={{height: "40px", float: "center"}}></img>
                {" + "}
                <img src={logoAmazonRelay} alt="Amazon Relay" style={{height: "26px", float: "center", margin: "8px 0 0 10px"}}></img>
               </GridItem>
            <GridItem xs={12}>
              <Typography className={classes.description}>
                <h3 className={classes.title}>ELD Software - TMS Software - PCS Software </h3>
                <h3 className={classes.title}>2SHIP - GPS TAB - M2M in Motion</h3>
                <h3 className={classes.title}>KEEP TRUCKING - EZ LOGS</h3>
                <h4 className={classes.title}>or any other you may need<span style={{ fontFamily: '"DINProBold", sans-serif' }}>!</span></h4>
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Software END */}
    </div>
  );
}

