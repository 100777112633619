import {
  title,
  whiteColor,
  basicColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";

const errorPageStyle = {
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "2",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: whiteColor,
    width: "100%",
    maxWidth: "500px",
    border: "4px solid #fff",
    borderRadius: "0px",
    backgroundColor:  "rgba(" + hexToRgb(blackColor) + ", 0.3)"
  },
  title: {
    ...title,
    color: whiteColor,
    letterSpacing: "16px !important",
    fontSize: "130px",
  },
  subTitle: {
    color: whiteColor,
    marginTop: "40px",
    marginBottom: "8px",
    letterSpacing: "6px !important"
  },
  description: {
    color: whiteColor,
    marginTop: "60px",
    marginBottom: "60px",
    fontSize: "17px",
    fontFamily: `"DINProRegular", sans-serif`,
    letterSpacing: "0.5px !important"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.4)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  }
};

export default errorPageStyle;
