import React, { useState, useEffect,  lazy, Suspense, CSSProperties } from 'react'
// components
import { Header, HeaderLinks } from 'components/indexComponents.js';
// images
import { siteLogo } from "pages/indexImages.js"

export default function SiteHeader() {
  return (
    <Header
      brand={
        <img src={siteLogo} alt="YOMIRO SOURCE" style={{height: "43px"}}/>
      }
      links={<HeaderLinks />}
      fixed
      color="white"
    />
  )
}