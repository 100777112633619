import {
  main,
  mainRaised,
  container,
  title,
  basicColor,
  yellowColor,
  whiteColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";

import { headerServices } from "pages/indexImages.js";

const servicesPageStyle = {
  main,
  mainRaised,
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "120px 0 120px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderText: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  pageHeaderTitle: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px 0 10px 0"
  },
  services: {
    margin: "auto",
    paddingTop: "10px",
  },
  section: {
    margin: "auto",
    padding: "20px 0",
    justifyContent: "center",
  },
  titleBox: {
    color: whiteColor,
    padding: "60px 0",
    fontSize: "28px",
    fontWeight: "300",
    textAlign: "center",
    border: "4px solid #fff",
    borderRadius: "0px",
  },
  tab: {
    color: whiteColor,
    textAlign: "center",
    borderBottom: "4px solid #fff",
    borderRadius: "0px",
    fontSize: "20px",
    fontWeight: "300",
    fontFamily: '"NordsternBold", sans-serif',
    "&:hover,&:focus,&:active": {
      color: whiteColor,
      background: "transparent",
      textAlign: "center",
      borderBottom: "4px solid #eda44a",
      borderRadius: "0px",
    }
  },
  title: {
    color: basicColor,
    padding: "5px 10px",
    margin: "auto",
    fontSize: "15px",
    fontWeight: "600",
    fontFamily: '"DINProRegular", sans-serif',
    textAlign: "justify",
  },
  description: {
    color: basicColor,
    fontSize: "15px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textAlign: "justify",
    padding: "3px 0",
    margin: "auto",
  },
  note: {
    color: "#aaa",
    padding: "0 10px 5px 10px",
    margin: "auto",
    fontSize: "13px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textAlign: "center",
  },
  contact: {
    color: basicColor,
    // maxWidth: "1000px",
    paddingBottom: "40px",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

export default servicesPageStyle;