import {
    container,
    title,
    section,
    blackColor,
    whiteColor,
    hexToRgb
  } from "components/primary-style.js";
  
  const sectionSoftware = {
    container,
    title: {
      ...title,
      textAlign: "center",
      textTransform: "none",
      marginTop: "20px"
    },
    software: {
      padding: "60px 0",
    },
    section: {
      ...section,
      position: "relative",
      "& $title": {
        color: whiteColor
      },
      "& $container": {
        zIndex: "2",
        position: "relative"
      },
      "&:after": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: "''",
        backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
      }
    },
    titleBox: {
      color: whiteColor,
      maxWidth: "900px",
      textAlign: "center",
      border: "4px solid #fff",
      borderRadius: "0px",
      padding: "30px 40px 30px 40px",
      marginLeft: "auto",
      marginRight: "auto",
      "&:hover,&:focus,&:active": {
        color: whiteColor,
        textAlign: "center",
        border: "4px solid #eda44a",
        borderRadius: "0px",
      }
    },
    description: {
      color: whiteColor,
      fontFamily: '"DINProRegular", sans-serif',
      fontSize: "15px",
      fontWeight: "400",
      textAlign: "center",
      margin: "auto",
    },
    logos: {
      float: "center",
      position: "relative",
      height: "30px",
      margin: "auto"
    },
    justifyContent: {
      WebkitBoxPack: "center !important",
      MsFlexPack: "center !important",
      justifyContent: "center !important"
    }
  };
  
  export default sectionSoftware;
  