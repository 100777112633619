import React from "react";
import classNames from "classnames";
// components
import { makeStyles, GridContainer, GridItem, Typography } from 'components/indexComponents.js';
// images
import { headerTechnology, logoDATinv, logoAmazonRelayinv } from "pages/indexImages.js";
// sections
import Breadcrumbs from "pages/TechnologyPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import technologyPageStyle from "pages/TechnologyPage/technologyPageStyle.js";

const useStyles = makeStyles(technologyPageStyle);

export default function TechnologyPage({ ...rest }) {
  const classes = useStyles();
  
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerTechnology})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>The Technology behind Truck Dispatch</h1>
              <h3 className={classes.pageHeaderTitle}>Best Software for Efficient Logistics</h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
        <Breadcrumbs />
        <h6 className={classes.title}>Software We use</h6>
            <div className={classes.container}>
            <GridContainer className={classes.titleBox}>
                <GridItem xs={12}>
                <Typography className={classes.description}>
                    All of our dispatchers are trained in using industry leading TMS Software. Working with 
                    a new company is always a smooth transition as we learn the ins and outs of each and every 
                    business and tailor a custom dispatch solution.
                </Typography>
                </GridItem>
                <GridItem xs={12} style={{padding: "30px 0 10px 0", justifyContent: "center"}}>
                    <img src={logoDATinv} alt="DAT Truckers Edge" style={{height: "40px", float: "center"}}></img>
                    {" + "}
                    <img src={logoAmazonRelayinv} alt="Amazon Relay" style={{height: "26px", float: "center", margin: "8px 0 0 10px"}}></img>
                </GridItem>
                <GridItem xs={12}>
                <Typography className={classes.description}>
                    <h3 className={classes.title}>ELD Software - TMS Software</h3>
                    <h3 className={classes.title}>GPS TAB - M2M in Motion - Keep Trucking - EZ LOGS</h3>
                    <h4 className={classes.title}>or any other you may need<span style={{ fontFamily: '"DINProBold", sans-serif' }}>!</span></h4>
                </Typography>
                </GridItem>
            </GridContainer>
            </div>
      </div>
    </div>
    <SiteFooter />
  </div>
  );
}

