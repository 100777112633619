import {
  container,
  main,
  mainRaised,
  title,
  basicColor,
  yellowColor,
  grayColor,
  whiteColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";

const pricingPageStyle = {
  main,
  mainRaised,
  container: {
    ...container,
    zIndex: "2",
    position: "relative"
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "120px 0 120px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
        background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderText: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  pageHeaderTitle: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px 0 10px 0"
  },
  section: {
    margin: "auto",
    padding: "0 0 30px 0",
    justifyContent: "center",
  },
  card: {
    borderRadius: "0",
    margin: "30px 15px 0 15px",
    background: whiteColor,
    width: "100%",
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(blackColor) +
      ", 0.12)",
  },
  titlePrice: {
    textAlign: "center",
    fontSize: "18px",
    marginTop: "10px",
  },
  title: {
    ...title,
    textAlign: "center",
  },
  description: {
    color: basicColor,
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "justify",
    padding: "0 20px 0 20px",
    margin: "auto",
  },
  descriptionLeft: {
    color: basicColor,
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "left",
    padding: "0 20px 30px 20px",
    margin: "auto",
  },
  price: {
    color: grayColor [0],
    fontSize: "16px",
    textAlign: "center",
    padding: "15px 0 20px 0"
  },
  contact: {
    textAlign: "center",
    border: "4px solid #ccc",
    borderRadius: "0px",
    padding: "30px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  icon: {
    margin: "3px 5px 0 0",
    verticalAlign: "top",
    fontSize: "18px",
  },
  button: {
    color: basicColor,
    textAlign: "center",
    border: "1px solid #ccc",
    borderRadius: "0px",
    padding: "5px 20px",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: '"NordsternBold", sans-serif',
    "&:hover,&:focus,&:active": {
      color: yellowColor,
      textAlign: "center",
      border: "1px solid #ccc",
      borderRadius: "0px",
      background: "transparent"
    }
  },
};

export default pricingPageStyle;
  