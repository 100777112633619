import React from "react";
import classNames from "classnames";
// components
import { makeStyles, Accordion, GridItem, GridContainer, PhoneNumber, Typography } from 'components/indexComponents.js';
//icons
import Call from "@material-ui/icons/Call";
// images
import { headerFaq } from "pages/indexImages.js";
// sections
import Breadcrumbs from "pages/FaqPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import faqPageStyle from "pages/FaqPage/faqPageStyle.js";

const useStyles = makeStyles(faqPageStyle);

export default function FaqPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
    <SiteHeader />
    <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerFaq})` }}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} className={classes.pageHeaderText}>
            <h1 className={classes.pageHeaderTitle}>Frequently Asked Questions</h1>
            <h4 className={classes.pageHeaderTitle}>Answers to your most common questions about our truck dispatching services</h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
      <div className={classes.container}>
        <Breadcrumbs />
        <GridContainer>
          <GridItem xs={11} sm={11} md={11} className={classes.accordion}>
            <Accordion active={0} activeColor="basic" collapses={[
                { title: "What is your process for hiring dispatcher for owner operator with own MC number?",
                  content: 
                  <Typography className={classes.description}>
                    You can start working with us in less than a week and the starting process is quite easy! 
                    <p/>All we need is: 
                    <br/>- Copy of Operating Authority (MC/DOT), 
                    <br/>- Copy of W9, 
                    <br/>- Copy of Insurance Certificate (Cargo and Liability),
                    <br />- Copy of our “Dispatch Service Agreement” signed and returned - the contract between Yomiro Source and 
                    your company.
                  </Typography>
                },
                { title: "How Experienced are your dispatchers?",
                  content:
                  <Typography className={classes.description}>
                    Our dispatchers experience starts from 3 years and the most experienced having 6 years in the 
                    business with prior brokerage experience and solid contacts within the industry, more experience 
                    is not always accounted for higher RPMs. Experience is imperative but let’s not forget focus and 
                    dedication. Yomiro RPMs didn’t go below waterline throughout 2019, with waterline being 2$/mile. 
                    Record Setting Gross came in at 19,000$ in the week of Christmas (power only, team drivers).
                  </Typography>
                },
                { title: "Do you dispatch Car Haulers and Hot Shots?",
                  content:
                  <Typography className={classes.description}>
                    No, our niche is Dry Vans, Reefers, Power Only, Amazon. 
                  </Typography>
                },
                { title: "I want to hire only camera monitoring team, do I need to purchase the rest of the services?",
                  content:
                  <Typography className={classes.description}>
                    We customize your request to suit your needs. Whether its one department or combination of all our services.
                  </Typography>
                },
                { title: "Do you take carriers with 8 trucks?",
                  content:
                  <Typography className={classes.description}>
                    No job is too big or to small, in fact under 150 truck operation benefits the most from outsourcing.
                  </Typography>
                },
                { title: "Can you handle top 10% trucking industry company size operation?",
                  content:
                  <Typography className={classes.description}>
                    No Job is too big or too small! Largest client of ours is 350 truck operation.
                  </Typography>
                },
                { title: "Is there a short-term source option available until inhouse help is found?",
                  content:
                  <Typography className={classes.description}>
                    Usually it's 3-month contracts with automatic renewal. If it's a short-term project, we have options available.
                  </Typography>
                },
                { title: "Do all your departments work 24/7 or just Night Dispatch?",
                content:
                <Typography className={classes.description}>
                  Each customer is a separate project for us, you tell us your needs and we will tailor to it.
                </Typography>
              },
              ]}
            />
          </GridItem>
          <GridItem xs={11} sm={11} md={11} className={classes.section}>
            <h4 className={classes.title}>What is Yomiro Source’s Mission?</h4>
            <Typography className={classes.description}>
              Our mission is to offer the best trucking outsource services on the market available today and 
              tomorrow. We are very fortunate and proud to have our current team of specialists on board. Few 
              words about our team: There are friends, there is family and then there are friends that become family.  
              <p/>Our job is to make your operation as smooth sailing as possible and most importantly our entire 
              team is focused and dedicated on this achievement, which is precious! We strive when our customers do, 
              so it’s imperative for us to get you highest paying loads, help finding the right drivers, offer 
              support to your drivers during after hours and treat your business as our own while offering great 
              customer service, so you are able to focus on core elements of your business. This is not “all 
              sales are final project”, we are here for the long lasting partnerships.
            </Typography>
            <Typography className={classes.title}>
              <p/>If you are looking for truck dispatch services to make you or your company profitable - contact Yomiro Source Now!
            </Typography>
          </GridItem>
          <GridItem xs={12}>
            <h3 className={classes.textCenter}>
              still have some questions<span style={{ fontFamily: '"DINProRegular", sans-serif' }}>?</span>
            </h3>
          </GridItem>
          <GridItem xs={12} sm={9} md={5} className={classes.contact}>
            <h4>
              Call Us .
              <Call className={classes.icon}/>
              <PhoneNumber />
            </h4>
            <h4>Leave us a message in Live Chat</h4>
          </GridItem>
          <GridItem xs={12}>
              <br /><h4 className={classes.textCenter}>We are at your service 24/7</h4>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    <SiteFooter />
  </div>
  );
}
