import React from "react";
// components
import { makeStyles } from 'components/indexComponents.js';
import { basicColor, yellowColor, whiteColor } from "components/primary-style.js";
import Call from "@material-ui/icons/Call";

const useStyles = makeStyles(theme => ({
  info: {
    color: basicColor,
    // padding: "0.9375rem",
    fontWeight: "600",
    fontSize: "15px",
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "0.3px !important",
    "&:hover, &:active, &:visited": {
      color: basicColor
    },
  },
  white: {
    color: whiteColor,
    padding: "5px 15px",
    fontSize: "18px",
    fontFamily: "'DINProBold', sans-serif",
    textTransform: "uppercase",
    textDecoration: "none",
    letterSpacing: "1px !important",
    "&:hover, &:active, &:visited": {
      color: whiteColor
    },
  },
  icon: {
    color: yellowColor,
    margin: "4px 10px 0 0",
    verticalAlign: "top",
    fontSize: "18px",
    "&:hover, &:active, &:visited": {
      color: basicColor
    },
  },
}));

export default function PhoneNumber(props) {
  const classes = useStyles();
  return (
    <a href="tel:14078132240" className={props.white?classes.white:classes.info}>
      <Call className={classes.icon}/>
      (407) 813-2240
    </a>
  )
}