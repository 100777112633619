/*eslint-disable*/
import React from "react";
// components
import { makeStyles, GridContainer, GridItem } from "components/indexComponents.js";
// images
import { headerError } from "pages/indexImages.js";
//  sections
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import errorPageStyle from "./errorPageStyle.js";

const useStyles = makeStyles(errorPageStyle);

export default function ErrorPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <SiteHeader />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + headerError + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        {/* <div className={classes.container}> */}
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>Page not found</h2>
              <h4 className={classes.description}>
                Oooops! Looks like you got lost.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        {/* </div> */}
      </div>
      <SiteFooter />
    </div>
  );
}
