import {
  container,
  title,
  section,
  basicColor,
  yellowColor,
  blackColor,
  whiteColor,
  hexToRgb
} from "components/primary-style.js";

const sectionServices = {
  container,
  title: {
    ...title,
    textAlign: "center",
    marginTop: "20px"
  },
  services: {
    padding: "60px 0",
  },
  section: {
    ...section,
    position: "relative",
    "& $title": {
      color: whiteColor
    },
    "& $container": {
      zIndex: "2",
      position: "relative"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    }
  },
  titleBox: {
    color: whiteColor,
    textAlign: "center",
    border: "4px solid #fff",
    borderRadius: "0px",
    padding: "60px 0",
    "&:hover,&:focus,&:active": {
      color: whiteColor,
      textAlign: "center",
      border: "4px solid #eda44a",
      borderRadius: "0px",
    }
  },
  link: {
    textDecoration: "none",
    "&:hover,&:focus,&:active": {
      textDecoration: "none"
    }
  },
  description: {
    color: whiteColor,
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "justify",
    justifyContent: "justify",
    padding: "20px 20px 20px 20px"
  },
  descriptionWide: {
    color: whiteColor,
    width: "800px",
    fontFamily: '"DINProRegular", sans-serif',
    fontSize: "15px",
    fontWeight: "400",
    textAlign: "center",
    margin: "auto",
  },
  button: {
    float: "right",
    color: whiteColor,
    textAlign: "center",
    border: "4px solid #fff",
    borderRadius: "0px",
    fontSize: "16px",
    fontWeight: "300",
    fontFamily: '"NordsternBold", sans-serif',
    "&:hover,&:focus,&:active": {
      color: whiteColor,
      textAlign: "center",
      border: "4px solid #eda44a",
      borderRadius: "0px",
    }
  },
  logos: {
    float: "center",
    position: "relative",
    height: "30px",
    margin: "auto"
  },
  justifyContent: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  }
};

export default sectionServices;
