import React from "react";
import classNames from "classnames";
// components
import { makeStyles, GridItem, GridContainer, Typography } from 'components/indexComponents.js';
// images
import { 
  headerClients, logoCadencePremier, logoFinco, logoGlobalExpress, logoOrlandoExpress, logoApexSecurity, logoGPSTab 
} from "pages/indexImages.js";
// sections
import Breadcrumbs from "pages/ClientsPage/sections/Breadcrumbs.js";
import SiteHeader from "components/Header/SiteHeader.js";
import SiteFooter from "components/Footer/SiteFooter.js";

import clientsPageStyle from "pages/ClientsPage/clientsPageStyle.js";

const useStyles = makeStyles(clientsPageStyle);

export default function ClientsPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div className="cd-section">
      <SiteHeader />
      <div className={classes.pageHeader} style={{ backgroundImage: `url(${headerClients})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} className={classes.pageHeaderText}>
              <h1 className={classes.pageHeaderTitle}>Our Clients</h1>
              <h3 className={classes.pageHeaderTitle}>Companies having successfully entrusted us with their logistics workload</h3>
              <h3 className={classes.pageHeaderTitle}>24 / 7 - 365</h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <div className={classNames(classes.main, classes.mainRaised)} id="main-panel">
        <div className={classes.container}>
          <Breadcrumbs />
          <GridContainer>
            <GridItem md={9}  className={classes.titleBox}>
              <Typography className={classes.descriptionTitle}>
                Having a professional truck dispatcher working with your company is one of the key elements 
                of success in trucking business. An efficient dispatcher is the difference between a company that 
                grows and maintains that growth and the one that fails quickly.
                Our mission is to provide the best truck dispatch services, allowing drivers to focus on driving 
                and companies to focus on business growth, while we fully take care of the rest.
              </Typography>
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.titleBox}>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoCadencePremier} alt="Cadence Premier Logistics" className={classes.logos}></img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>Cadence Premier Logistics</span> - a full service, asset-based 
                logistics company outside Chicago selling, leasing and renting trucks and trailers, as well as 
                providing logistics and transportation services. Its entities include Cadence Premier Logistics, 
                Cadence Truck &amp; Trailer Sales and Cadence Truck &amp; Trailer Leasing.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoFinco} alt="FinCo Logistics" className={classes.logos}
                   style= {{height: "65px", marginTop: "-13px", marginBottom: "-13px"}}>
              </img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>FinCo Trucking Inc.</span> - a licensed and bonded interstate freight 
                shipping and trucking company running freight hauling business from Arlington Heights, Illinois.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoGlobalExpress} alt="Global Express" className={classes.logos}></img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>Global Express Inc.</span> - Federally licensed Non-Vessel Operating 
                Common Carrier (NVOCC) offering international ocean shipping from all US major ports to over 145 
                destinations around the world via RORO, Container, LCL, Air Freight, Domestic Transport, Heavy Haul, 
                RV Transport, RV Shipping, Machinery Shipping, Boat Shipping, Auto Loading and more, as well as 
                providing warehousing and loading services across the country in strategically located port cities.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoOrlandoExpress} alt="Orlando Express" className={classes.logos} 
                   style= {{height: "55px", marginTop: "-13px", marginBottom: "-3px"}}>
              </img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>Orlando Express Trucking Inc.</span> - a licensed and bonded 
                freight shipping and trucking company running freight hauling business from Orlando, Florida.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoApexSecurity} alt="Apex Security" className={classes.logos}></img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>APEX Security Inc.</span> provides security services and security 
                officers for more than 500 companies, universities, malls, gated communities, film productions, 
                warehouses, special events, construction sites and hotels in Orlando, Windermere, Tampa, Daytona and 
                surrounding areas. A family business with over 40 years of collective experience in the Security 
                Industry and personal attention to every client.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <img src={logoGPSTab} alt="GPSTab ELD/AOBRD" className={classes.logos}></img>
              <Typography className={classes.description}>
                <span style={{fontWeight: "600"}}>GPSTab ELD/AOBRD</span> - the most powerful suite of tools to manage 
                your fleet operation needs and to comply with FMCSA regulations. Most accurate and relevant data to 
                help you manage your ﬂeet. Monitor current location, speed, miles traveled, route selection, detention 
                time, and other driver activities to improve safety and overall operations of your entire fleet.
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={9} className={classes.disclaimer}>
              <Typography className={classes.disclaimerText}>
                * The logos, trademarks, company names (not owned by Yomiro Source) specified and displayed hereinabove 
                are registered and unregistered marks of their respective owners and are protected by their respective 
                copyrights.
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}
