import React from "react";
// components
import { makeStyles, GridContainer, GridItem, Hidden, Link, Typography } from 'components/indexComponents.js';
// images
import { imgDayDispatch, imgNightDispatch, imgRecruiting, imgSafety, imgData, imgCameras } from "pages/indexImages.js";

import sectionServicesStyle from "pages/HomePage/sections/sectionServicesStyle.js";

const useStyles = makeStyles(sectionServicesStyle);

export default function SectionServices({ ...rest }) {
  const classes = useStyles();
  
  return (
    <div className="cd-section" {...rest}>
      <h6 className={classes.title} style={{marginTop: "0"}}>EFFICIENT TRUCK DISPATCHING</h6>
      <br/>
      {/* Night-Dispatch START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgNightDispatch})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
              <Link color="inherit" href="/" className={classes.link}>
                <h1 className={classes.title}>NIGHT-TIME <br />DISPATCH</h1>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
              Once your day operation team is done for the day, our night crew is there to provide updates to your 
              customers, perform tasks such as load recoveries, minor break downs, safety support, booking loads and 
              updating your customers. Our staff is well trained on assisting with minor repairs that can be quite 
              expensive. Accident procedures, check calls or simply payment for lumpers or other services, you are 
              all covered and can rest assured your day crew will be well informed on all services or issues 
              occurred throughout the night shift.
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Night-Dispatch END */}
      {/* Day-Dispatch START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgDayDispatch})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
              <Link color="inherit" href="/" className={classes.link}>
                <h1 className={classes.title}>DAY-TIME <br />DISPATCH</h1>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
              Reduce your operational cost and increase productivity. Yomiro Source 
              offers professional truck dispatch services that includes booking loads, broker setup completion, e-mail/phone 
              dispatch and updates. Click on the left to learn more how you can externalize these tasks to our dispatchers 
              who work in a centralized location coordinating and managing delivery processes for your customers 
              ground-transported cargo. Our specialty: Dry Van, Reefer, Amazon, Power Only. Most recent weekly gross 
              record for team drivers, week before Christmas Sun Midnight to Sunday Midnight was 19,000$ power only.
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Day-Dispatch END */}
      {/* Recruiting START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgRecruiting})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
              <Link color="inherit" href="/" className={classes.link}>
                <h1 className={classes.title}>RECRUITING</h1>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
                If you are looking to hire a driver, we can help you find the right fit for your team, whether 
                your company has the right recipe or we can suggest proven methodology to acquire and retain talents. 
                Our combined experience and efforts helped more than 2300 drivers to find right carriers.
              </Typography>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Recruiting END */}
      <h6 className={classes.title}>WORKING SMART WITH ADVANCED TECHNOLOGIES</h6>
      <br/>
      {/* Safety Support START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgSafety})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <Hidden smUp>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>SAFETY <br />SUPPORT</h1>
                </Link>
              </GridItem>
            </Hidden>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
                ELD Mandate leaves no room for mistakes! poorly planned routes could cost companies 
                up to 30% in gross revenue. Yomiro Source staff was trained by industry finest and 
                have extensive experience to help your dispatchers plan routes as well as reduce 
                HOS violations by checking your driver's log-books 24/7 to make sure all duty statuses 
                are selected correctly and in compliance with HOS Regulations.
              </Typography>
            </GridItem>
            <Hidden smDown>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>SAFETY <br />SUPPORT</h1>
                </Link>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </div>
      {/* Safety Support END */}
      {/* Data Entry START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgData})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <Hidden smUp>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>DATA <br />ENTRY</h1>
                </Link>
              </GridItem>
            </Hidden>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
                Logistics Data Entry is an active part of trucking dispatch operation where mistakes can cost 
                fortunes. Data entry is a time consuming process that requires high degree of focus and technical 
                competency. Our Data Entry Staff been a great addition to many companies so they can focus on 
                their core operation at maximum efficiency. We provide high-quality cost-effective logistics 
                data entry support to transportation businesses and companies involved in similar field.
              </Typography>
            </GridItem>
            <Hidden smDown>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>DATA <br />ENTRY</h1>
                </Link>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </div>
      {/* Data Entry END */}
      {/* Camera START */}
      <div
        className={classes.services + " " + classes.section}
        style={{ backgroundImage: `url(${imgCameras})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <Hidden smUp>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>CAMERA SCREENING <br />&amp; MONITORING</h1>
                </Link>
              </GridItem>
            </Hidden>
            <GridItem xs={12} sm={12} md={6}>
              <Typography className={classes.description}>
                Whether it’s surveillance camera monitoring for security agency project or for advanced trucking 
                company, we have the right set of eyes to monitor your valuable assets. Trucks installed with 
                cameras that are accessible on demand by tap of the finger lets Safety Conscious Carriers or 
                Insurance Providers to be rest assured on their monitoring project. Yomiro Source is armed with 
                experienced staff that can adapt our service approach to suit the specific needs of your 
                organization or specific project to ensure the highest level of service is reached.
              </Typography>
            </GridItem>
            <Hidden smDown>
              <GridItem xs={12} sm={12} md={6} className={classes.titleBox}>
                <Link color="inherit" href="/" className={classes.link}>
                  <h1 className={classes.title}>CAMERA SCREENING <br />&amp; MONITORING</h1>
                </Link>
              </GridItem>
            </Hidden>
          </GridContainer>
        </div>
      </div>
      {/* Camera END */}
    </div>
  );
}

