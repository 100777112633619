import {
  container,
  yellowColor,
  title,
} from "components/primary-style.js";

const advantagesStyle = {
  container,
  title: {
    padding: "20px 0"
  },
  subtitle30: {
    ...title,
    marginTop: "10px",
    marginBottom: "30px",
    padding: "0 20px 0 20px",
    fontSize: "13.3px",
    fontWeight: "600",
    fontFamily: '"NordsternRegular", sans-serif',
    letterSpacing: "0.3px !important"
  },
  subtitle10: {
    ...title,
    marginTop: "10px",
    marginBottom: "10px",
    padding: "0 20px 0 20px",
    fontSize: "13.3px",
    fontWeight: "600",
    fontFamily: '"NordsternRegular", sans-serif',
    letterSpacing: "0.3px !important"
  },
  subtitleCalls: {
    ...title,
    marginTop: "0",
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    fontSize: "13.3px",
    fontWeight: "600",
    fontFamily: '"NordsternRegular", sans-serif',
    letterSpacing: "0.3px !important",
    border: "2px solid #56595c",
    borderRadius: "0px"
  },
  description: {
    padding: "0 50px 0 50px"
  },
  about: {
    textAlign: "center",
    padding: "0",
    marginLeft: "auto",
    marginRight: "auto",
  },
  advantages: {
    padding: "20px 0 10px 0"
  },
  icon: {
    color: yellowColor,
    height: "26px",
    width: "26px"
  },
  phoneContainer: {
    "& img": {
      width: "100%"
    }
  },
};

export default advantagesStyle;
