import {
  container,
  main,
  mainRaised,
  whiteColor,
  blackColor,
  hexToRgb
} from "components/primary-style.js";

const homePageStyle = {
  main,
  mainRaised,
  container: {
    ...container,
    zIndex: "2",
    position: "relative"
  },
  pageHeader: {
    position: "relative",
    height: "auto",
    backgroundPosition: "50%",
    backgroundSize: "cover",
    margin: "0",
    padding: "120px 0 120px 0",
    display: "flex",
    WebkitBoxAlign: "center",
    MsFlexAlign: "center",
    alignItems: "center",
    "&:before": {
      background: "rgba(" + hexToRgb(blackColor) + ", 0.6)"
    },
    "&:after,&:before": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''"
    }
  },
  pageHeaderTitle: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto"
  },
  title: {
    color: whiteColor,
    textTransform: "uppercase",
    textDecoration: "none",
    padding: "0 30px 0 30px"
  },
  titleUnderline: {
    color: whiteColor,
    fontSize: "16px",
    paddingTop: "20px"
},
  titleDescription: {
    color: whiteColor,
    padding: "10px 0 20px 0"
  },
  button: {
    color: whiteColor,
    textAlign: "center",
    border: "4px solid #fff",
    borderRadius: "0px",
    fontSize: "20px",
    fontWeight: "300",
    fontFamily: '"NordsternBold", sans-serif',
    "&:hover,&:focus,&:active": {
      color: whiteColor,
      background: "transparent",
      textAlign: "center",
      border: "4px solid #eda44a",
      borderRadius: "0px",
    }
  },
};

export default homePageStyle;
