import React from 'react';
// components
import { makeStyles, Breadcrumbs, Link, Typography } from 'components/indexComponents.js';
// icons
import ArrowRight from '@material-ui/icons/ArrowRight';

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    padding: "10px 0 10px 0"
  },
  breadcrumb: {
    color: "#9ba7b1",
    fontSize: "12px"
  },
  link: {
    color: "#9ba7b1",
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3px !important",
    "&:hover, &:visited": {
      color: "#9ba7b1"
    },
  },
  linkCurrent: {
    color: "#9ba7b1",
    fontSize: "12px",
    fontWeight: "300",
    fontFamily: '"DINProRegular", sans-serif',
    textTransform: "uppercase",
    letterSpacing: "0.3px !important"
  }
}));

export default function SimpleBreadcrumbs() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRight />} className={classes.breadcrumb}>
          <Link color="inherit" href="/" className={classes.link}>
            Home
          </Link>
          <Typography className={classes.linkCurrent}>
            About Us
          </Typography>
        </Breadcrumbs>
    </div>
  );
}
